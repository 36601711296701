import React, { useState, useContext } from 'react';
import Search from './search/Search';
import LocationDisplay from './locationDisplay/LocationDisplay';
import { getDeviceLogs } from '../../services/mtDiagnosticsService';
import DiagnosticContext from '../app/AppContext';
import './Locations.scss';

/**
 * locations class
 */
function Locations(props) {
  const [pointInterval, setPointInterval] = useState(90);
  const [transmissionInterval, setTransmissionInterval] = useState(630);

  const diagnostics = useContext(DiagnosticContext);

  /**
   * Clears the search results
   * @returns {Promise<void>}
   */
  async function clearResults() {
    props.setLocations(null);
  };

  /**
   * Adds a CSS class to an element
   * @param id
   * @param className
   */
  function addClass(id, className) {
    let element = document.getElementById(id);
    element.classList.add(className);
  };

  /**
   * Removes a CSS class from an element
   * @param id
   * @param className
   */
  function removeClass(id, className) {
    let element = document.getElementById(id);
    element.classList.remove(className);
  };

  /**
   * Searches for device logs
   * @async
   * @param serialNumber
   * @param startTime
   * @param endTime
   * @param convert
   * @param pointInterval
   * @param transmissionInterval
   * @returns {Promise<void>}
   */
  async function search(serialNumber, startTime, endTime, convert, pointInterval, transmissionInterval, blockType, locationIdToggle) {
    props.setsearch((oldSearch) => { return { ...oldSearch, startTime, endTime, convert, blockType, locationIdToggle } });
    removeClass('loader', 'hidden');
    await clearResults();
    let logs = await getDeviceLogs(props.search.serialNumber || props.search.victimId, startTime, endTime, props.productType);

    if(props.productType !== 'victim') {
      markDuplicatePoints(logs);
      props.setLocations(translatePoints(logs));
    } else {
      markDuplicateVictimPoints(logs);
      props.setLocations(translateVictimPoints(logs));
    }

    setPointInterval(pointInterval);
    setTransmissionInterval(transmissionInterval);
    addClass('loader', 'hidden');
  };

  /**
   * Marks device log records with any groups of duplicates they belong to
   * @param deviceLogs
   * @returns {void}
   */
  function markDuplicatePoints(deviceLogs) {
    let flatLogs = [];

    for(let log of deviceLogs) {
      if(log.payload && log.payload.location)
        log.payload.location.map(loc=> flatLogs.push(loc));
    }

    for(let log of flatLogs) {
      let x = 0;

      while(x < flatLogs.length) {
        if(log !== flatLogs[x] && log.fixtime === flatLogs[x].fixtime) {
          if(!log.duplicate)
            log.duplicate = {count: 1};

          flatLogs[x].duplicate = log.duplicate;
          flatLogs.splice(x, 1);
          
          log.duplicate.count++;
        } else {
          x++;
        }
      }
    }
  }

  function translatePoints(deviceLogs) {
    return deviceLogs.reduce((arr, log) => {
      let {payload} = log;
      return [
        ...arr,
        ...payload.location.map(location =>{
          return { ...location, receivedDate: log.receivedDate} 
        })
      ];
    }, []);
  }

  function markDuplicateVictimPoints(deviceLogs) {
    let flatLogs = [];

    for(let log of deviceLogs) {
      if(log.payload && log.payload.location)
        log.payload.location.map(loc=> flatLogs.push(loc));
    }

    for(let log of flatLogs) {
      let x = 0;

      while(x < flatLogs.length) {
        if(log !== flatLogs[x] && log.fixtime === flatLogs[x].fixtime) {
          if(!log.duplicate)
            log.duplicate = {count: 1};

          flatLogs[x].duplicate = log.duplicate;
          flatLogs.splice(x, 1);
          
          log.duplicate.count++;
        } else {
          x++;
        }
      }
    }
  }

  function translateVictimPoints(deviceLogs) {
    return deviceLogs.reduce((arr, log) => {
      let {location, receivedDate, payload} = log;
      if(payload)
        location = payload._v;
        return [
          ...arr,
          ...location.map(location =>{
            return { receivedDate, ...location.coords, accuracy: Math.round(location.coords.accuracy * 10000)/10000, fixtime: location.timestamp, uuid: location.uuid, ...location.activity, level: Math.round(location.battery.level * 100), mobile: log.mobile} 
          }) 
        ];
    }, [])
  }

  return (
    <div className={'container'}>
      <div className={'row p-2'}>
        <div className={'col'}>
          <Search onSearch={search} search={props.search} timezone={diagnostics.timezone} productType={props.productType} />
          <hr />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col'}>
          <LocationDisplay
            locations={props.locations}
            pointInterval={pointInterval}
            transmissionInterval={transmissionInterval}
            timezone={diagnostics.timezone}
            productType={props.productType}
            movementTypeBlock={props.search.blockType === 'movement'}
            displayLocationId={props.search.locationIdToggle}
          />
        </div>
      </div>
      <div className={'loader spinner-border text-primary hidden'} id={'loader'}>&nbsp;</div>
    </div>
  )
}

export default Locations;
